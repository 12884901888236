<template>
    <div class="banner">
        <p>在接受本协议之前，请您仔细阅读并遵守本协议的全部内容。请您务必审慎阅读、充分理解各条款的内容，包括但不限于免除或者限制{{title}}网（简称“{{title}}”）责任的条款、对用户权利进行限制的条款、违约责任、争议解决和法律适用条款等。如果您对本协议的条款有疑问的，请通过{{title}}网客服渠道进行询问，{{title}}网将向您解释条款内容。如果您不同意本协议的任意内容，或者无法准确理解{{title}}网对条款的解释，请不要进行任何后续操作。否则，表示您已接受了以下所述的条款和条件，同意受本协议约束。届时您不应以未阅读本协议的内容或者未获得{{title}}网对您问询的解答等理由，主张本协议无效，或要求撤销本协议。</p>
        <div class="declara_headline">{{title}}软件服务协议</div>
        <div class="declara_headline_state">{{title}}版权声明</div>
        <p>1、{{title}}所收集数据均来自其他网站页面抓取，并未经过人工干预，如抓取的数据侵犯贵公司的权利，请发邮件指出，我们将及时纠正。</p>
        <p>2、{{title}}是免费分享数据的平台，请勿将本站数据用于商业用途，由此引发的其他商业纠纷或法律责任，本站概不负责。</p>
        <p>3、未经({{title}})的明确许可，任何人不得盗链本站资源；不得复制或仿造本网站或者在非({{title}})所属的服务器上建立镜像，({{title}})对其自行开发的或和他人共同开发的所有内容、技术手段和服务拥有全部知识产权，任何人不得侵害或破坏。</p>
        <p>为了给您提供更准确、更有个性化的服务，{{title}}会按照本隐私政策的规定使用和披露您的个人信息。但{{title}}将以高度的勤勉、审慎义务对待这些信息。除本隐私政策另有规定外，在未征得您事先许可的情况下，{{title}}不会将这些信息对外披露或向第三方提供。{{title}}会不时更新本隐私政策。 您在同意{{title}}服务使用协议之时，即视为您已经同意本隐私政策全部内容。本隐私政策属于{{title}}服务使用协议不可分割的一部分。</p>
        <p class="declare_title">一、适用范围</p>
        <p>1、在您使用{{title}}服务，或访问{{title}}平台网页时，视为您同意{{title}}自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
        <p>2、{{title}}通过合法途径从商业伙伴处取得的用户个人数据。</p>
        <p class="declare_title">二、信息使用</p>
        <p>1、{{title}}不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可；</p>
        <p>2、{{title}}亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何{{title}}平台用户如从事上述活动，一经发现，{{title}}有权立即终止与该用户的服务协议；</p>
        <p>3、为服务用户的目的，{{title}}可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与{{title}}合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>
        <p class="declare_title">三、特别说明</p>
        <p>1、为识别用户的需要，{{title}}插件安装成功后，在您自愿激活成功并开始使用起，{{title}}插件将读取有限的浏览器Cookie信息，包括：用户账号（旺旺号），用户识别号，通过{{title}}所取得的用户识别信息，将适用本政策。</p>
        <p>2、{{title}}插件根据用户的需要，经用户点击功能按钮后，提交指数数据到{{title}}的服务器，服务器通过转换算法转换指数返回。</p>
        <p>4、{{title}}插件安装后，在您浏览网站过程中，{{title}}会在不影响网站使用的前提下，适当修改页面结构，以方便您能使用{{title}}的服务和功能。您可以随时通过删除插件停止使用。</p>
        <p>5、基于上述行为，{{title}}不承担生意参谋被封停使用的任何责任。</p>
        <p>6、礼品单用于商家赠送回馈消费者/老客户所有，用于其他操作行为不予本站接受；</p>
        <p>7、礼品赠送，会按照本隐私政策的规定使用保护商家和消费者的个人信息；</p>
        <p>8、参加赠送的商家，不能用于炒作信用等违规违法行为；如发现操作有违法行为，将立即解除和本站合作事项，且违规违法行为产生的一切后果由商家自行承担；</p>

        <div class="declara_headline">{{title}}工具隐私政策</div>
        <p>温馨提示：{{title}}尊重并保护所有使用服务用户的个人隐私权。</p>
        <p class="declare_title">一、信息披露</p>
        <p>在如下情况下，{{title}}将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
        <p>1、根据法律的有关法律规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
        <p>2、如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
        <p>3、其它{{title}}根据法律、法规或者网站政策认为合适的披露。</p>
        <p>4、大电商及其他平台提供的所有内容仅供学习、分享与交流，我们不保证内容的正确性。通过使用本站内容随之而来的风险与本站无关。当使用本站时，代表您已接受本站的免费声明和隐私原则等条款。</p>

    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    computed:{
        title(){
            return this.$store.state.title;
        }
    }
}
</script>

<style lang="less" scoped>
    .banner{
        background: #fff;
        padding: 50px 20px;
        font-size: 14px;
        color: #333;
        p{
            margin: 5px 0;
        }
        .declara_headline{
            font-size: 20px;
            text-align: center;
            margin-top: 20px;
            line-height: 30px;
        }
        .declara_headline_state{
            text-align: center;
            margin: 10px 0;
        }
        .declare_title{
            font-size: 16px;
            margin-top: 20px;
        }
    }
</style>